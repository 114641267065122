import React from 'react';
import EachProject from '../EachProject/EachProject';
import SideNav from '../SideNav/SideNav';


const Projects = () => (
    <div className='projects'>
 <SideNav /> 
 <EachProject /> 
    </div>
);
export default Projects;