import React from 'react';
import './footer.css';


const Footer = () => (

    <div className='footer-container'>
      <p className="copyright"> © Londa Ploetz 2023 </p>
    </div>

);
export default Footer;